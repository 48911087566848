<template>
  <main class="med-info">
    <TopBlock
      :title="'Сервис МедИнфо'"
      :description="'МедИнфо — это научно-медицинский сервис компании «АстраЗенека» для предоставления ответов на запросы врачей и пациентов. Медицинская информация может быть связана с клиническим использованием препарата, фармакологическими аспектами, и иной научной информацией по препаратам компании «АстраЗенека».'"
      :images="[
        {
          src: require('@/assets/img/med-info/375.jpg'),
          media: '(max-width: 767px)',
        },
        {
          src: require('@/assets/img/med-info/768.jpg'),
          media: '(max-width: 1220px)',
        },
        {
          src: require('@/assets/img/med-info/1440.jpg'),
          media: '(min-width: 1221px)',
        },
      ]"
    />
    <Breadcrumbs :pages="[{ name: 'МедИнфо' }]" />
    <div class="content">
      <div class="med-info__text">
        <span>МедИнфо</span> — это научно-медицинский сервис компании
        «АстраЗенека». Медицинская информация может быть связана с клиническим
        использованием препарата, фармакологическими аспектами, и иной научной
        информацией по препаратам компании «АстраЗенека». <br />Наши научные
        материалы не служат целям продвижения компании, основаны на актуальной
        опубликованной научной информации и подкреплены соответствующими
        ссылками. Материалы регулярно пересматриваются и обновляются. Для вашего
        удобства все документы доступны на русском языке.
        <br />
        <br />
        Наши специалисты ответят на ваши вопросы о лекарственных препаратах
        компании «АстраЗенека» и терапевтических областях: кардиология,
        онкология, пульмонология, нефрология, иммунология и инфекционные
        заболевания.
      </div>
      <div class="med-info__title">Поиск медицинской информации</div>
      <div class="med-info__description mb-8">
        Для поиска информации выберите препарат из выпадающего списка и введите
        не менее двух ключевых слов по теме запроса на русском языке:
      </div>
      <div class="d-xl-flex d-block mb-8">
        <Select
          :taggable="false"
          :push-tags="false"
          class="mr-4 mr-md-0 mb-md-4 med-info__select"
          v-model="medication"
          :items="medication_list"
          :placeholder="'Выберите препарат'"
          :multiple="false"
          :rules="[medLength || 'Выберите препарат']"
          searchable
        />
        <TextField
          :disabled="!medication.length"
          class="mr-4 mr-md-0 mb-md-4 med-info__textfield"
          :type="'text'"
          v-model="name"
          :rules="[isError || 'd']"
        >
          <template v-slot:append-icon>
            <div class="med-info__search-icon px-4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10.5"
                  cy="10.5001"
                  r="6"
                  stroke="#3C4242"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M15 15.0001L19.5 19.5001"
                  stroke="#3C4242"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div> </template
          ><template v-slot:alternate-error
            ><div class="med-info__input-tip">
              Необходимо внести минимум два слова
            </div></template
          ></TextField
        >
        <div class="button button_pink med-info__button" @click="search">
          Поиск
        </div>
      </div>
      <div class="med-info__text">
        Обращаем ваше внимание на то, что компания «АстраЗенека» не может
        предложить персонализированные подходы к лечению или дать
        совет/рекомендации по лечению конкретных пациентов.
      </div>
      <div class="med-info__items mb-8" v-if="Docs && Docs.length">
        <div class="med-info__item" v-for="i in Docs.length" :key="i">
          <div class="med-info__item-body">
            <div class="med-info__item-image-container">
              <img
                :src="require(`@/assets/img/med-info/doc-eg.svg`)"
                class="med-info__item-image"
              />
              <span>{{
                get_url_extension(Docs[i - 1].file).toUpperCase()
              }}</span>
            </div>
            <div class="med-info__item-text-container">
              <a
                :href="Docs[i - 1].file"
                target="_blank"
                class="med-info__item-name"
                v-html="Docs[i - 1].headline_summary"
              >
              </a>
              <div class="med-info__item-date" v-if="Docs[i - 1].update_date">
                Документ одобрен до: {{ formatDate(Docs[i - 1].update_date) }}
              </div>
              <div class="d-block d-xl-flex ml-auto ml-md-0 mt-auto">
                <a
                  :href="Docs[i - 1].file"
                  download=""
                  class="button button_empty-pink mr-4 mr-md-0 mb-md-4"
                >
                  Скачать документ
                  <svg
                    class="ml-2"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 2.25012C12.4142 2.25012 12.75 2.58591 12.75 3.00012V13.1895L16.4697 9.46979C16.7626 9.1769 17.2374 9.1769 17.5303 9.46979C17.8232 9.76269 17.8232 10.2376 17.5303 10.5305L12.5303 15.5305C12.4584 15.6024 12.3755 15.6566 12.2871 15.6932C12.1987 15.7299 12.1017 15.7501 12 15.7501C11.8983 15.7501 11.8013 15.7299 11.7129 15.6932C11.6245 15.6566 11.5416 15.6024 11.4697 15.5305L6.46967 10.5305C6.17678 10.2376 6.17678 9.76269 6.46967 9.46979C6.76256 9.1769 7.23744 9.1769 7.53033 9.46979L11.25 13.1895V3.00012C11.25 2.58591 11.5858 2.25012 12 2.25012ZM3 14.2501C3.41421 14.2501 3.75 14.5859 3.75 15.0001V19.0001C3.75 19.3316 3.8817 19.6496 4.11612 19.884C4.35054 20.1184 4.66848 20.2501 5 20.2501H19C19.3315 20.2501 19.6495 20.1184 19.8839 19.884C20.1183 19.6496 20.25 19.3316 20.25 19.0001V15.0001C20.25 14.5859 20.5858 14.2501 21 14.2501C21.4142 14.2501 21.75 14.5859 21.75 15.0001V19.0001C21.75 19.7295 21.4603 20.4289 20.9445 20.9447C20.4288 21.4604 19.7293 21.7501 19 21.7501H5C4.27065 21.7501 3.57118 21.4604 3.05546 20.9447C2.53973 20.4289 2.25 19.7295 2.25 19.0001V15.0001C2.25 14.5859 2.58579 14.2501 3 14.2501Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
                <div
                  :class="{
                    button_disabled: lockedDocs.includes(Docs[i - 1].id),
                  }"
                  v-if="user && user.email"
                  @click="sendToMail(Docs[i - 1].id)"
                  class="button button_empty-pink mb-md-4"
                >
                  Отправить документ на почту
                  <svg
                    class="ml-2"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.5 4.00012H20.5C21.6 4.00012 22.5 4.90012 22.5 6.00012V18.0001C22.5 19.1001 21.6 20.0001 20.5 20.0001H4.5C3.4 20.0001 2.5 19.1001 2.5 18.0001V6.00012C2.5 4.90012 3.4 4.00012 4.5 4.00012Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22.5 6.00012L12.5 13.0001L2.5 6.00012"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="Docs && !Docs.length"
        class="med-info__title mb-8 mt-4"
        style="text-align: center"
      >
        По вашему запросу ничего не найдено
      </div>
      <!-- <div class="med-info__load button button_pink" @click="loadMore()" v-if="Docs.length >= 5">
        Показать ещё
        <span class="ml-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 12.7501L4.5 12.0001H3V12.7501H4.5ZM19.5 12.7501C19.5 16.8923 16.1421 20.2501 12 20.2501L12 21.7501C16.9706 21.7501 21 17.7207 21 12.7501L19.5 12.7501ZM12 20.2501C7.85786 20.2501 4.5 16.8923 4.5 12.7501H3C3 17.7207 7.02944 21.7501 12 21.7501L12 20.2501ZM12 5.25012C16.1421 5.25012 19.5 8.60799 19.5 12.7501L21 12.7501C21 7.77956 16.9706 3.75012 12 3.75012V5.25012ZM7.56864 6.69856C8.8101 5.78779 10.3412 5.25012 12 5.25012V3.75012C10.0114 3.75012 8.17143 4.39596 6.68136 5.48912L7.56864 6.69856Z"
              fill="#B36697"
            />
            <path
              d="M8.08391 1.05776L6.37094 6.61929L11.9325 8.33225"
              stroke="#B36697"
              stroke-width="1.5"
            />
          </svg>
        </span>
      </div> -->
      <!-- <div class="med-info__title mt-12">Нужна дополнительная информация?</div> -->
      <div class="med-info__blocks d-xl-flex d-block">
        <!-- <div class="med-info__block mr-4 mr-md-0 mb-md-4">
          <div class="med-info__block-icon">
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="56" height="56" rx="28" fill="#830051" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.9757 17.5412C20.5253 16.9967 21.2692 16.6923 22.0432 16.6923H30.524C30.7369 16.6923 30.9412 16.7763 31.0924 16.9261L37.453 23.2261C37.6061 23.3778 37.6923 23.5844 37.6923 23.8V23.9576L37.6923 23.9615L37.6923 23.9655V36.4C37.6923 37.1736 37.3819 37.914 36.832 38.4587C36.2823 39.0032 35.5384 39.3076 34.7644 39.3076H22.0432C21.2692 39.3076 20.5253 39.0032 19.9757 38.4587C19.4257 37.914 19.1154 37.1736 19.1154 36.4V19.6C19.1154 18.8263 19.4257 18.0859 19.9757 17.5412ZM31.2308 19.3368L35.0845 23.1538H31.2308V19.3368ZM22.0432 18.3076H29.6154V23.9615C29.6154 24.4076 29.977 24.7692 30.4231 24.7692H36.0769V36.4C36.0769 36.7402 35.9405 37.0681 35.6952 37.311C35.4497 37.5543 35.115 37.6923 34.7644 37.6923H22.0432C21.6927 37.6923 21.358 37.5543 21.1124 37.311C20.8671 37.0681 20.7307 36.7402 20.7307 36.4V19.6C20.7307 19.2597 20.8671 18.9318 21.1124 18.6889C21.358 18.4457 21.6927 18.3076 22.0432 18.3076ZM23.9615 28C23.5154 28 23.1538 28.3616 23.1538 28.8077C23.1538 29.2538 23.5154 29.6154 23.9615 29.6154H32.8461C33.2922 29.6154 33.6538 29.2538 33.6538 28.8077C33.6538 28.3616 33.2922 28 32.8461 28H23.9615ZM23.1538 33.6538C23.1538 33.2078 23.5154 32.8461 23.9615 32.8461H32.8461C33.2922 32.8461 33.6538 33.2078 33.6538 33.6538C33.6538 34.0999 33.2922 34.4615 32.8461 34.4615H23.9615C23.5154 34.4615 23.1538 34.0999 23.1538 33.6538ZM23.9615 23.9615C23.5154 23.9615 23.1538 24.3231 23.1538 24.7692C23.1538 25.2153 23.5154 25.5769 23.9615 25.5769H26.3846C26.8307 25.5769 27.1923 25.2153 27.1923 24.7692C27.1923 24.3231 26.8307 23.9615 26.3846 23.9615H23.9615Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="med-info__block-title">Отправить вопрос</div>
          <div class="med-info__block-description">
            Отправить Ваш запрос по медицинской информации и получить ответ
            удобным для Вас способом по телефону или электронной почте, Вы
            можете <a href="#" target="_blank">заполнив форму</a>
          </div>
        </div> -->
        <div class="med-info__block ml-md-0">
          <!--ml-4-->
          <div class="med-info__block-icon">
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="56" height="56" rx="28" fill="#830051" />
              <path
                d="M38.7692 33.2985V36.5293C38.7704 36.8292 38.7089 37.1261 38.5888 37.4009C38.4686 37.6757 38.2924 37.9224 38.0714 38.1251C37.8504 38.3279 37.5895 38.4823 37.3054 38.5783C37.0212 38.6744 36.7202 38.7101 36.4215 38.6831C33.1076 38.323 29.9244 37.1907 27.1276 35.377C24.5256 33.7235 22.3195 31.5175 20.6661 28.9154C18.8461 26.106 17.7134 22.9073 17.3599 19.5785C17.333 19.2807 17.3684 18.9806 17.4639 18.6972C17.5593 18.4138 17.7127 18.1534 17.9143 17.9326C18.1159 17.7117 18.3613 17.5353 18.6348 17.4145C18.9083 17.2936 19.204 17.2311 19.503 17.2308H22.7338C23.2564 17.2257 23.7631 17.4107 24.1594 17.7515C24.5556 18.0923 24.8145 18.5656 24.8876 19.0831C25.024 20.117 25.2769 21.1322 25.6415 22.1093C25.7864 22.4947 25.8177 22.9136 25.7318 23.3164C25.6459 23.7191 25.4464 24.0888 25.1569 24.3816L23.7892 25.7493C25.3222 28.4454 27.5546 30.6778 30.2507 32.2108L31.6184 30.8431C31.9112 30.5536 32.2809 30.354 32.6836 30.2681C33.0863 30.1823 33.5052 30.2136 33.8907 30.3585C34.8678 30.7231 35.8829 30.976 36.9169 31.1124C37.44 31.1862 37.9178 31.4497 38.2593 31.8527C38.6008 32.2558 38.7823 32.7704 38.7692 33.2985Z"
                stroke="white"
                stroke-width="1.79487"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="med-info__block-title">Свяжитесь с нами</div>
          <div class="med-info__block-description">
            Если вы не нашли необходимую информацию, вы можете направить нам
            запрос:
            <ul>
              <li>
                по электронной почте
                <a href="mailto: MedInfo.ru@astrazeneca.com"
                  >MedInfo.ru@astrazeneca.com</a
                >
              </li>
              <li>
                через форму
                <a
                  href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/ru/ru/amp-form.html"
                  target="_blank"
                  >CHAMPion</a
                >
              </li>
              <li>
                по телефону
                <a href="tel:84957995699*6"
                  >+7&nbsp;(495)&nbsp;799&nbsp;56&nbsp;99</a
                >, доб.&nbsp;6&nbsp;(понедельник - пятница с 9:00 до 18:00)
              </li>
            </ul>

            При направлении запроса, пожалуйста, укажите название, лекарственную
            форму и дозировку препарата компании «АстраЗенека», а также подробно
            опишите вопрос. Также по Вашему запросу мы можем предоставить
            интересующую Вас научную публикацию, относящуюся к препаратам
            компании (полнотекстовые статьи предоставляются только в оригинале,
            на английском языке).
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
 

<script>
import TopBlock from "@/components/pageComponents/TopBlock.vue";
import Breadcrumbs from "@/components/pageComponents/Breadcrumbs.vue";
import Select from "@/components/form-elements/Select.vue";
import TextField from "@/components/form-elements/TextField.vue";
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
export default {
  metaInfo: {
    title: "Сервис МедИнфо",
  },
  name: "MedInfo",
  components: {
    TopBlock,
    Breadcrumbs,
    Select,
    TextField,
  },
  data: () => ({
    medication: [],
    name: "",
    lockedDocs: [],
  }),
  computed: {
    ...mapGetters(["Drugs", "Docs", "user"]),
    medication_list() {
      return this.Drugs.map((el) => ({ label: el.name, value: el.id }));
    },
    isError() {
      return this.name.split(" ").filter((el) => !!el).length >= 2;
    },
    medLength() {
      return !!this.medication.length;
    },
  },
  methods: {
    ...mapActions(["fetchDrugs", "fetchDocs", "sendDoc"]),
    search() {
      bus.data.result = [];
      if (this.isError) {
        this.fetchDocs({ id: this.medication, q: this.name, o: 0 });
      }
      bus.$emit("validate", this._uid);
    },
    loadMore() {
      this.fetchDocs({
        id: this.medication,
        q: this.name,
        o: this.Docs.length,
      });
    },
    sendToMail(id) {
      this.sendDoc({ id: id, email: this.user.email });
      this.lockedDocs.push(id);
    },
    formatDate(date) {
      let noFomatDate = new Date(date);
      return `${noFomatDate.getDate().toString().padStart(2, 0)}.${(
        noFomatDate.getMonth() + 1
      )
        .toString()
        .padStart(2, 0)}.${noFomatDate.getFullYear()}`;
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
  },
  async mounted() {
    this.fetchDrugs();
  },
};
</script>

<style lang="scss" scoped>
.med-info {
  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1f1f1f;
    margin-bottom: 48px;
    margin-top: 32px;

    & span {
      color: #830051;
    }
  }

  &__search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    background-color: #f8f8f8;
  }

  &__input-tip {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #b2b4b4;
  }

  &__button {
    width: 176px;
    height: 40px;
  }

  &__load {
    width: 175px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
    margin-bottom: 24px;
  }

  &__select {
    width: 384px;

    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }

  &__textfield {
    width: 592px;

    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }

  &__block {
    // width: 50%;
    padding: 24px;
    border: 1px solid #e9ebed;

    & ul {
      margin: 8px 0;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
      list-style: disc;
      padding-left: 16px;
      a {
        display: inline-block;
        color: #d0006f;
      }

      & li {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-icon {
      margin-bottom: 16px;
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;

      & a {
        color: #830051;
        text-decoration: underline;
      }
    }
  }

  &__item {
    margin-bottom: 24px;
    padding: 24px 16px;
    border: 1px solid #d2d2d2;

    &:last-child {
      margin-bottom: 0;
    }

    &-body {
      display: grid;
      grid-template-columns: 110px 1fr;
      column-gap: 24px;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 148px 1fr;
      }

      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &-image-container {
      width: 110px;
      height: 128px;
      flex-shrink: 0;
      position: relative;

      & > span {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #830051;
      }

      @media screen and (max-width: 1220px) {
        width: 148px;
        height: 206px;
      }

      @media screen and (max-width: 768px) {
        width: 110px;
        height: 128px;
        margin: 0 auto 24px;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      object-position: center;
    }

    &-name {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #830051;
      margin-bottom: 8px;
      cursor: pointer;
    }

    &-date {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8b8e8e;
      margin-bottom: 16px;
    }

    &-text-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }
}

.textfield_disabled {
  .med-info__search-icon {
    background-color: #ebefee;
  }
}
</style>

<style lang="scss">
.med-info {
  & .textfield__input-container,
  & .textfield__input,
  & .selectaria__select {
    max-width: none;
  }

  & .textfield__input {
    padding-right: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__item-name {
    &:hover {
      text-decoration: underline;
    }
    & b {
      font-weight: 600;
    }
  }
}
</style>